.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    justify-content: space-between;
        align-items: center;
    }
.cookie-banner p {
    margin: 0;
    flex: 1;
}
.cookie-banner button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.cookie-banner button:hover {
    background-color: #0056b3;
}
.cookie-banner button:focus {
    outline: none;
}