/* ul li:first-child  {
    font-size: x-large;
    font-weight: bold;
} */

video {
    width: "100%";
    height: "40vh";
    object-fit: cover !important;
    display: block !important;
}