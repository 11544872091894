.container-baja-cuenta {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.titulo-baja-cuenta {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.mensaje-baja-cuenta {
  margin-bottom: 20px;
  line-height: 1.5;
  text-align: center;
}

.instrucciones-baja-cuenta {
  margin-bottom: 20px; 
  line-height: 1.5;
  text-align: center;
}

.texto-baja {
  color: red;
  font-weight: bold;
}

.input-email-baja-cuenta {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease; 
}

.input-email-baja-cuenta:focus {
  border-color: #007bff; 
  outline: none;
}

.dar-baja {
  background-color: #ee032a;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dar-baja:hover {
  background-color: #af2e2e;
}

.error-baja p {
  text-align: start;
  color: #dc3545;
  margin-top: 10px;
}
