
/* ------------------------------------------------ PERFIL ------------------------------------------------ */
.estado {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #f0f0f0;
  color: #333;
  text-align: center;
}

.scroll-contenedor-menu{
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}
.scroll-contenedor-menu::-webkit-scrollbar {
  display: none; 
}

.tabla-success {
  background-color: #d4edda;
  border-radius: 4px;
  padding: 5px 10px;
  color: #155724;
  display: inline-block;
  border: 1px solid #c3e6cb;
}

.tabla-warning {
  background-color: #fff3cd;
  border-radius: 4px;
  padding: 5px 10px;
  color: #856404;
  display: inline-block;
  border: 1px solid #ffeeba;
}

.tabla-danger {
  background-color: #f8d7da;
  border-radius: 4px;
  padding: 5px 10px;
  color: #721c24;
  display: inline-block;
  border: 1px solid #f5c6cb;
}

.small-icon {
  font-size: 0.8rem;
  line-height: 1;
}

.config {
  font-size: 24px;
}

.btn-transparente {
  background-color: transparent;
  border: none;
  color: black;
  width: 80%;
  outline: none;
}

.btn-transparente:focus,
.btn-activo-profile:focus {
  outline: none;
  box-shadow: none;
}

.btn-activo-profile {
  width: 80%;
  background-color: #9fa6b27e !important;
  color: black !important;
}
.btn-activo-mobile{
  font-weight: bold !important;
}

.romper-texto {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.profile-picture {
  max-width: 100%;
  height: auto;
}

.nombre-usuario {
  font-size: 2.5vw;
}

@media (max-width: 770px) {
  .nombre-usuario {
    font-size: 4vw;
  }

}

/* ------------------------------------------------ INFORMACION  ------------------------------------------------ */
