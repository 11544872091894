@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Bricolage Grotesque", serif !important;
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

p{
  font-family: "Bricolage Grotesque", serif !important;
}

code {
  font-family: "Bricolage Grotesque", serif;
}

button{
  border: none;
  background-color: transparent;
}

/*Centra los datos de las filas en el medio de forma vertical*/
table tr td {
  vertical-align: middle !important;
}

/*Corregir la paginacion de la libreria MUI React*/
.MuiTablePagination-toolbar {
  padding: 0 !important;
}

.MuiTablePagination-toolbar p {
  margin: 0;
  align-items: center;
}

/*Estilo del th tabla MUI React*/
.MuiTableCell-head {
  font-weight: bold !important;
}

/*Quitar las mayusculas por defecto de los botones*/
.MuiButton-root {
  font-size: 1rem !important;
  text-transform: none !important;
  height: 2.5rem;
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

.circulo-estado {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: .2rem;
  animation: pulsar 1.5s infinite; /* Llama a la animación pulsar */
}

/* Definición de la animación */
@keyframes pulsar {
  
  50% {
    box-shadow: 0 0 10px var(--color); /* Sombra con color dependiente del estado */
  }
}

.contenedor-loading{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* margin-top: 10%; */
  gap:15px;
  height: 100%;

  & span{
    font-size: 24px;
    color:#FFD400;
    font-weight: 600;
  }
}
.contenedor-logo{
  clip-path: inset(0);
  position: relative;

  & img{
    width: 225px;
    height: 260px;
  }

  & .capa-loading{
    position: absolute;
    width: 100%;
    height: 100% ;
    background-color: #ffff;
    top: 0;
    mix-blend-mode: hue;
    animation: loadingLogo 1s linear 1 forwards;
  }
}

@keyframes loadingLogo {
  0%{
    height: 100%;
  }
  100%{
    height: 0%;
  }
}



