@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

.texto-roboto {
  font-family: 'Roboto', sans-serif !important;
}

.texto-merriweather {
  font-family: 'Merriweather', serif !important;
}

.texto-pacifico {
  font-family: 'Pacifico', cursive !important;
}

.texto-oswald {
  font-family: 'Oswald', sans-serif !important;
}

.texto-playfairDisplay {
  font-family: 'Playfair Display', serif !important;
}

.texto-lobster {
  font-family: 'Lobster', cursive !important;
}

.texto-anton {
  font-family: 'Anton', sans-serif !important;
}

.texto-indieFlower {
  font-family: 'Indie Flower', cursive !important;
}

.texto-abrilFatface {
  font-family: 'Abril Fatface', serif !important;
}

.texto-fjallaOne {
  font-family: 'Fjalla One', sans-serif !important;
}

/* header {
  display: none;
} */