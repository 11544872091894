body {
    font-family: Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1.3;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    background-color: #f4f5f6;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    /* display: flex; */
    justify-content: center;
    align-items: center;
}

.alert-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.main {
    border-collapse: separate;
    /* Color del fondo del contenedor de la alerta */
    /* background: #fff7e6; */
    background-color: white;
    border: 1px solid #eaebed;
    border-radius: 16px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.wrapper {
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    vertical-align: top;
    box-sizing: border-box;
    padding: 24px;
    text-align: center;
}

p {
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 16px;
}

.event-name {
    font-weight: bold;
    font-style: italic;
}

.btn-container {
    border-collapse: separate;
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    margin-bottom: 1rem;
}

.button-group {
    display: flex;
    justify-content: space-between;
}

.btn-primary-link,
.btn-secondary-link {
    width: 48%;
    /* Ajustar el ancho de ambos botones para que sean iguales */
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding: 12px 0;
    text-decoration: none;
    text-transform: capitalize;
    text-align: center;
}

.btn-primary-link {
    border: solid 2px #337deb;
    background-color: #0D6DFD;
    color: #ffffff;
}

.btn-secondary-link {
    border: solid 2px #ee6455;
    background-color: #e74c3c;
    color: #ffffff;
}

.btn-primary-link:hover {
    background-color: #1753a8 !important;
    /* Cambio de color al hacer hover */
    border-color: #1b4eb4 !important;
    color: #ffffff7c;
    text-decoration: none;
}

.btn-secondary-link:hover {
    background-color: #c0392b !important;
    border-color: #c03525 !important;
    color: #ffffff7c;
    text-decoration: none;
}

.saludo {
    font-size: 13px;
}

.redirecting-message {
    color: #007bff;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 10px;
}

@media only screen and (max-width: 640px) {

    .main p,
    .main td,
    .main span {
        font-size: 16px !important;
    }

    .wrapper {
        padding: 8px !important;
    }

    .content {
        padding: 0 !important;
    }

    .container {
        padding: 0 !important;
        padding-top: 8px !important;
        width: 100% !important;
    }

    .main {
        border-left-width: 0 !important;
        border-radius: 0 !important;
        border-right-width: 0 !important;
    }

    .btn-container table {
        max-width: 100% !important;
        width: 100% !important;
    }

    .btn-container a {
        font-size: 16px !important;
        max-width: 100% !important;
        width: 100% !important;
    }
}