.button-share {
  background-color: transparent !important;
  border: transparent !important;
  color: #000 !important;
}
.button-share:hover {
  color: #0a53be !important;
}
.button-special{
  background-color: transparent !important;
  border: transparent !important;
  color: white !important;
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: #0a53be !important;
  box-shadow: none !important;
}

.custom-dropdown-menu {
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.drop_item {
  /* padding: 0.5rem; */
  font-size: 16px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.custom-dropdown-menu.show {
  margin-top: 5px;
  display: flex !important;
  flex-direction: row !important;
}
.drop_item-whatsapp {
  border-left: 1px solid #c1c0c3 !important;
  border-right: 1px solid #c1c0c3 !important;
}
.rrss {
  width: 25px;
  height: 25px;
}
