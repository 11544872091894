.boton-filtros-mobile {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .contenedor-filtros-mobile {
    display: flex;
    flex-direction: column;
  }
  
  .category-button-mobile {
    background: none;
    border: none;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap;
    font-size: 16px;
    color: #000;
    transition: color 0.3s, border-bottom 0.3s;
  }
  
  .category-button-mobile:hover {
    color: #007bff;
  }
  
  .category-button-mobile.active {
    border-bottom: 2px solid #000;
    color: #000;
  }
  
  .date-component {
    margin-right: 20px;
  }
  
  .rmdp-container{
    width: 100%;
  }
 .calendar-mobile{
  padding-bottom: 20px;
  border-bottom: 1px solid #c4c4c4;
 }
  
  .container-calendar-mobile input {
    width: 100%;
    border-radius: 10px !important;
  }
  
  .botones-categorias-mobile{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    max-width: 600px; 
  }
  .filtro-categorias-mobile{
    margin-top:20px;
  }
  
  .category-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .category-item:hover {
    background-color: #f5f5f5;
  }
  
  .category-radio {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .category-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .category-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px !important;
    height: 32px !important;
    margin-right: 16px;
  }
  
/*   .icon-category-mobile {
    color: #0B0062;
    width: 28px !important;
    height: 28px !important;
    font-weight: 300 !important;
  } */
  
  .category-text {
    flex-grow: 1;
  }
  
  .category-radio:checked + .category-content {
    font-weight: bold;
  }
  
  .boton-buscar-filtros-mobile{
    margin-top: 10px;
    width: 100%;
    border-radius: 10px;
    height: auto !important;
  }

  .boton-region-filtro{
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    padding: 5px 8px;
  }

  .input-region-mobile{
    border-color: #c4c4c4;
    width: 100%;
    border-radius: 10px;
    height: 56px;
    padding: 3px 20px;
  }

  .boton-fecha-filtro{
    border-left: 1px solid #c4c4c4;
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    padding: 5px 8px;
  }

.button-ads-mobile:hover {
  background-color: #abadf8 !important;
}
  
.button-ads-mobile.active {
  background-color: #abadf8 !important;
}

  
.button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 100%;
}

.icon-category-mobile {
  color: #5558ff;
  width: 38px !important;
  height: 38px !important;
  font-weight: 300 !important;
  transition: all 0.3s ease;
}

.text-btn-category {
  margin: 0;
  font-size: 14px;
  color: #5558ff;
  text-align: center;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.category-slider-container {
  width: 100%;
}

.category-slider {
  margin: 0; 
}

.category-slide {
  padding: 0 3px;
  transition: all 0.3s ease;
}

.button-ads-mobile {
  cursor: pointer;
  background-color: #e6e6ff;
  border-radius: 15px;
  width: 110px;
  height: 110px;
  transition: all 0.3s ease;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transform: scale(0.95);
}

.slick-track {
  display: flex;
  align-items: center;
  margin-left: 0; 
}

.slick-slide {
  opacity: 0.8;
  transition: all 0.3s ease;
}

.slick-slide.slick-center {
  opacity: 1;
}

/* .slick-slide.slick-center .button-ads-mobile {
  transform: scale(1);
} */

.slick-slide > div {
  display: flex;
  justify-content: center;
  width: 100%;
}
