/* homeHeader.css
.slider-container {
  position: relative;
  width: 100%;

  margin: 0 auto;
}

.slider-content {
  position: relative;
}

.slider-img {
  width: 100%;
  height: auto;
  display: block;
}

.nav-buttons {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  width: 100%;
}

button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
} */

.img-slider-home {
  filter: none !important;
  opacity: 1 !important;
  mix-blend-mode: normal !important;
}