/* ----------HERO--------- */
.hero-div-crear {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: white;
    position: relative;
    z-index: 1;
    gap:20px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.hero-title {
    color: #5656FF;
    font-size: 60px;
    font-weight: bold;
    line-height: 64px;
    text-align: center;
}


@media (max-width: 1100px) {
    .hero-div-crear{
        justify-content: flex-start;
    }

    .hero-title{
        font-size: 32px;
        line-height: 40px;
    }
}

.hero-h3 {
    text-align: center;
    font-size: 35px;
    font-style: italic;
    color: #221A60;
}
/* -----------HERO SLIDER---------------- */
.hero-slider{
    width: 100%;
    /* margin-left: 5px; */
    background-color: transparent;
    display: flex;
    flex-direction: row;
}
.item-slide-hero{
    text-align: center;
    width: 130px;
    border-radius: 8px;
}
.slide-hero{
    margin-top: 12px;
}

/* --------FIRST SECTION--------- */
.info-first-div {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 40px;
    position: relative;
    background-color: #FFFFFF;
}

.info-first-div-title {
    font-style: italic;
    padding: 10px;
    text-align: center;
    color: #221A60;
    font-weight: bold;
}

.info-first-div-p {
    font-style: italic;
    padding: 10px;
    text-align: center;
    width: 70%;
    font-size: 20px
}

.info-first-div-button {
    background-color: #0B0062;
    color: white;
    justify-self: end;
    font-size: 20px;
    border-radius: 10px;
    padding: 10px 15px;
}

.info-first-div-button:hover {
    background-color: #5656FF;
    color: white;
}

.info-first-div-a {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
}

.info-first-div-a:hover {
    color: white;
    text-decoration: none;
}

/* -------SECOND SECTION -------- */
.info-second-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    background-image: url("../../../assets/fondonuevo.svg");
    background-size: cover;
    background-position: bottom;
    /* overflow: hidden; */

}

.info-second-div-title {
    color: #221A60;
    /* margin-top: 30px; */
    position: relative;
    bottom: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

/* slider */
.slider-container {
    width: 100%;
    margin: 0 auto;
    /* overflow: hidden; */

}

.slick-list {
    width: 100%;
    /* max-width: 1400px; */
}

.slide {
    padding: 0 15px;
    width: 100%;
    /* max-width: 1200px */
    ;
}

.slide-card {
    background: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 18px;
    height: 280px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.slide-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #221A60;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 13px;
}

.slide-description {
    color: #221A60;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
}

@media (max-width: 1024px) {
    .slide-card {
        min-height: 280px;
        width: 100%;
    }

    .slide {
        width: 100%;
        max-width: 500px;
        height: auto;
    }
}

@media (max-width: 600px) {
    .hero-div-crear img {
        width: 80%;
    }

    .slide-card {
        min-height: 200px;
        width: 100%;
    }

    .slide {
        width: 100%;
        max-width: 350px;
    }
}

@media (max-width: 480px) {
    .slide-card {
        width: 100;
    }
}

.costumer-slider .slick-dots li button:before {
    color: #0B0062;
    font-size: 8px;
}

.costumer-slider .slick-dots li.slick-active button:before {
    color: #0B0062;
    font-size: 8px;
}

/* ------------ COSTUMERS ----------- */
.costumers-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #CCCCFF;
    padding-top: 52px;
    padding-bottom: 0;
}

.costumers-div-title {
    color: #221A60;
    font-weight: bold;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    text-align: center;
    line-height: 40px;
}

.name-costumer {
    line-height: 48px;
    letter-spacing: 0;
    font-size: 36px;
}
.css-hmd0fc-MuiRating-root{
    font-size: 25px !important;
}
.text-div-costumer {
    width: 90%;
    text-align: center;
}

.text-p-costumer {
    color: black;
    font-size: 24px !important;
    line-height: 36px;
   /*  font-style: italic; */
    text-align: center;
}

.slider-indiv{
    width: 85% !important;
    background-color: #FFFFFF;
    padding-top: 52px;
    padding-bottom: 52px;
    border-radius: 16px;
    margin-bottom: 52px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.costumer-slider .slick-list {
    max-width: 100%;
}

.costumer-slider .slick-slide {
    display: flex;
    justify-content: center;
    padding: 0 15px;
}

.slick-dots-wrapper {
    width: 100%;
    display: inline-flex;
    justify-content: center;
}

.costumer-slider .slick-dots {
    position: relative;
    bottom: 95px;
}

.costumer-slider .slick-dots li button:before {
    color: #0B0062;
    font-size: 12px;
}

.costumer-slider .slick-dots li.slick-active button:before {
    color: #0B0062;
    font-size: 12px;
}
/* --------BADGE AMARILLO ------ */
.container-badge-amarillo{
    background-color: white;
    height: auto;
    width: 100%;
}
.badge-amarillo{
    background-color: #FFE97D;
    border-radius: 32px;
    margin: 30px 15%;
    height: 100%;
    padding: 28px 0;
}

@media (max-width: 770px){
    .badge-amarillo{
        padding: 5px;
        margin: 10px 5%;
    }
}

.content-badge-amarillo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-text-badge-amarillo{
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.text1-badge-amarillo{
    margin-bottom: 0;
    font-size: 28px;
    line-height: 36px;
    font-weight: bold;
}
.text2-badge-amarillo{
    font-size: 22px;
    line-height: 36px;
    margin-bottom: 0;
}
/* -------CONTACT US------- */
.contactus-div {
    background-color: #E6E6FF;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 20px;
    /* align-items: center; */
    margin-top: 10px;
}

.contactus-div-form {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    /* width: 90%; */
    justify-content: center;
    margin-bottom: 30px;
    margin-left: 12%;
    margin-right: 12%;
}

/* .contactus-div-form-form {
    width: 70%;
    margin: 30px;
} */

.social-div {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
}

.social-div-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
    flex: 1;
    position: relative;
    width: 100%;
}

.social-img-logo {
    width: 60%;
}

.social-div-redes {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    pad: 4px;
    padding-left: 30px;
    justify-content: space-around;
}

.redes-h5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #221A60;
    font-weight: 600;
    margin-right: 15px;
}

.span-sm {
    font-size: 15px;
    margin-top: -20px;

}

@media (max-width: 500px) {
    .redes-h5 {
        font-size: 16px;
    }

    .social-div-redes {
        padding-left: 10px;
    }
}

@media(max-width: 600px) {
    .info-first-div {
        flex-direction: column;
        align-items: center;
    }

    .contactus-div {
        flex-direction: column;
        align-items: center;
    }

    .contactus-div-form-form {
        width: 100%;
    }

    .contactus-div-form {
        width: 100%;
    }

    .social-div-redes {
        flex: 1.5;
    }


}

@media(max-width: 700px) {
    .info-second-div-h3 {
        font-size: 30px;
    }

    .info-second-div-p {
        font-size: 16px;
    }

    .info-second-div {
        height: 400px;
    }

    .social-img-logo {
        width: 80%;
    }

}




/* --------------------- BADGE CELESTE ------------------- */ 

.container-badge-celeste{
    background-color: #5656FF;
    height: auto;
    padding:25px;
    border-radius: 24px;
    margin: 35px 12%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.text-badge-celeste{
    color: white;
    font-size: 18px;
    text-align: center;
    font-weight: 200;
    padding-left: 10%;
    padding-right: 10%;
}

@media (max-width: 770px){
    .container-badge-celeste{
        padding: 10px;
        margin: 40px 10%;
    }
    .text-badge-celeste{
        font-size: 16px;
        text-align: justify;
    }
}

.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border: none;
    margin-right: -10px;
    position: relative;
    z-index: 2;
}

.custom-checkbox:checked {
    appearance: auto;
    -webkit-appearance: auto;
    accent-color: #28a745; 
}

.option-text {
    border: 1px solid #FFD700;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    color:#0B0062;
}

.option-text.selected {
    background-color: #FFD700;
}



.subtitle-services{
    text-align: center;
    -size: 20px;
    color:#0B0062;
}

.services{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
}

.service-picture, .picture-service{
    display: flex;
    flex-direction: row;
    height: 400px;
    margin-left: 12%;
    margin-right: 12%;
}
