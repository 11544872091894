@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.button-ads-desktop {
  cursor: pointer;
  background-color: #e6e6ff;
  border-radius: 15px;
  width: 100%;
  height: 100px;
  transition: background-color 0.4s ease;
}

.button-ads-desktop:hover,
.button-ads-desktop.active {
  background-color: #abadf8 !important;
}

.icon-category {
  width: 50px !important;
  height: 50px !important;
  transition: color 0.4s ease;
}

.text-btn-category {
  font-size: 14px;
  color: #5558ff !important;
  margin-bottom: 0;
  /* margin-top: 20px; */
  font-family: "Inter", sans-serif;
  font-weight: 500;
  text-align: center;
  transition: color 0.4s ease;
}

.container-calendar input {
  position: relative;
  bottom: 1px;
  background-color: transparent;
  height: 56px !important;
  /* border-radius: 4px; */
  border: 1px solid #C4C4C4;
  padding: 3px 20px;
  outline: none;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}

.rmdp-input {
  border-radius: 0 !important;
}


.container-calendar input:focus {
  border: 2px solid #1770C8 !important; 
}

.date-picker {
  background-color: #E4E4E4;
}

.boton-buscar-filtros{
  color: white;
  font-weight: bold;
  height: 56px !important;
  width: 30%;
  padding: 5px 8px;
  /* border: 1px solid #C4C4C4; */
  background-color: #5656FF;
  border-radius: 0 10px 10px 0;
  font-family: "Inter", sans-serif;
}

.boton-buscar-filtros:hover{
  background-color: #16068d;
}
.boton-limpiar-filtros{
  color: red;
  background-color: transparent;
  outline: none;
  font-weight: normal;
  margin-left: 10px;
  font-size: 14px;
}
.content-boton-filtros{
  margin-top: auto;
}
.txt-boton-filtros{
  font-family: "Bricolage Grotesque";
}

.input-date-home {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  text-align: center;
  height: 2.5em;
  border: 1px solid transparent;
  cursor: pointer;
}

.input-date-home:focus {
  outline: 1px solid rgb(10, 88, 194) !important;
}

::-webkit-calendar-picker-indicator {
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.btn-ad:hover {
  background: #C2DAFF !important;
}

.btn-ad-more:hover {
  background: transparent !important;
  color: #0d6efd !important;
}

/***************************************************************************************************/


.category-button-mobile {
  background: none;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  color: #000;
  transition: color 0.3s, border-bottom 0.3s;
}

.category-button-mobile:hover {
  color: #007bff;
}

.category-button-mobile.active {
  border-bottom: 2px solid #000;
  color: #000;
}

.date-component {
  margin-right: 20px;
}

.container-calendar-mobile {
  margin-top: 10px;
  display: flex;
  color: red;
  justify-content: center;
}

.container-calendar-mobile input {
  background-color: transparent;
  color: black;
  border-radius: 10px;
  width: 100%;
}

.container-calendar-mobile input::placeholder {
  color: #fff;
}

.card-publicacion-indiv {
  align-items: center;
  display: flex;
  gap:10px;
  height: 300px;
  /* transition: transform 0.6s ease !important; */
}
/* .card-publicacion-indiv:hover {
  transform: scale(1.02);
} */
.media-publicacion-indiv {
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
.contenido-publicacion-indiv {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 7px !important;
  height: 100%;
  gap:8px
  /* padding-bottom: 16px !important; */
}
.title-publicacion-indiv {
  font-family: "Bricolage Grotesque";
  font-size: 24px;
  font-weight: 600;
  margin: 5px 0 5px 0;
  line-height: 1.2;
}

@media (max-width:1200px){
  .card-publicacion-indiv{
    width: 90%;
  }
}

.card-publicacion {
  width: 280px;
  /* height: 380px; */
  display: flex;
  flex-direction: column;
  transition: transform 0.6s ease !important;
}
.card-publicacion:hover {
  transform: scale(1.02);
}

.media-publicacion {
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
}

.contenido-publicacion {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 7px !important;
  /* padding-bottom: 16px !important; */
}

.title-publicacion {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin: 5px 0 5px 0;
  line-height: 1.2;
}

.text-publicacion {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

.fechaHora-publicacion {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}


.descripcion {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  margin-top: 5px;
}

.contenedor-badge {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 10px;
  border-radius: 8px;
}

.contenedor-badge small{
  font-weight: 500;
}

.boton-publicacion{
  outline: none;
  border-radius: 10px;
  background-color: #5656FF;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #F5F5F5;
  padding: 6px 15px;
  text-align: center;
}
.boton-publicacion:hover{
  background-color: #16068d;
}

.separador-horizontal {
  width: 100%; 
  border-bottom: 1px solid #D3D3D3; 
  margin: 10px 0; 
  margin-bottom: 20px;
}

.input-region{
  width: 40%;
  height: 56px;
}

.contenedor-card{
  margin-left: 12%;
  margin-right: 12%;
  border: 1px solid #EDEDED;
  border-radius: 10px;
  
}