@media (max-width: 770px) {
    .contenedor-evento{
        padding: 0 !important;
    }

    .seccion-evento{
        width: 100% !important;
    }
    .boton-category-mobile{
        display: flex;
        flex-direction: column;
    }
    .contenido-evento{
        padding: 5px 10px !important;
    }
    .data-hora-form{
        flex-direction: column;
    }

}
@media (max-width: 450px){
    .date-hora{
        gap: 0 !important;
    }
}


.contenedor-evento {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

#form-evento {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
}

/* ===============================CARDS==================================  */
.seccion-evento {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: auto;
    padding: 20px;
    margin-bottom: 20px;
}

.seccion-evento.expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    background: white;
    padding: 10px;
}

.contenido-evento {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 5px 15px 5px 15px;
}

.date-hora{
    /* d-flex flex-wrap gap-4 align-items-center mb-2 */
    /* display: flex;
    flex-wrap: wrap; */
    /* gap: 30px; */
    align-items: center;
    margin-bottom: 10px;
}

.titulo-seccion-evento {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 0;
   /*  text-align: right; */
}
.titulo-seccion-evento-expanded {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
}

.subtitulo-seccion-evento-expanded{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}

.descrip-seccion-evento {
    font-size: 14px;
}
.descrip-seccion-evento-expanded {
    font-size: 14px;
    text-align: justify;
}

.hidden {
    display: none;
}

/* ===============================CARD IMAGENES==================================  */
.input-file {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: auto;
    padding: 10px;
    border: 1px dashed #ccc;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
}

.placeholder-input-img {
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.imagen-contenedor {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.main-preview {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s;
    border-radius: 6px;
}

.remove-button {
    position: absolute;
    top: 10px; 
    right: 10px; 
    padding: 2px 6px;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: none; 
    font-size: 12px;
}

.main-preview:hover .remove-button {
    display: block; 
}
.main-preview img,
video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.thumbnails-contenedor {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    overflow-x: auto;
}

.thumbnail,
.boton-agregar-img {
    width: 80px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.tooltip-fotos {
	margin-top: 10px;
	font-size: 14px;
	color: #555;
}
.thumbnail.selected {
    border-radius: 6px;
    border: 2px solid #3659E3; 
}

.thumbnail img,
.thumbnail video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
}

.badge {
    position: absolute;
    bottom: 10px;
    /* right: 10px; */
    background-color: rgba(54, 89, 227, 0.9); 
    color: white;
    padding: 2px 6px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    z-index: 10;
    pointer-events: none; 
}

.thumbnail {
    cursor: grab;
    position: relative;
}

.thumbnail.drag-over {
    border: 2px dashed #3659E3;
}

.boton-agregar-img {
    border: 1px solid #ccc;
    font-size: 24px;
    background-color: #f0f0f0;
    border-radius: 3px;
}

.boton-tipo-active,
.boton-tipo {
    font-size: 16px;
    padding: 8px 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
}
.boton-tipo-active {
    border: 1px solid #3659E3;
    color: #3659E3;
    background-color: #f0f4ff;
}

.boton-tipo {
    border: 1px solid #ccc;
    color: #333;
    background-color: transparent;
}

.indicator-wrapper {
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.indicator-fuera {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    transition: all 0.2s ease;
}

.indicator-dentro {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #3659E3;
    transition: all 0.2s ease;
    transform: translate(-50%, -50%);
}

.boton-tipo-active .indicator-fuera {
    border-color: #3659E3;
}

.boton-tipo-active .indicator-dentro {
    width: 10px;
    height: 10px;
}

.boton-tipo:hover {
    border-color: #3659E3;
    color: #3659E3;
}

.boton-tipo:hover .indicator-fuera {
    border-color: #3659E3;
}

.boton-submit{
    padding: 8px 10px !important;
    font-family: "Inter", sans-serif !important;
    background-color: #5656FF !important;
    outline: none !important;
    color: #f5f5f5 !important;
}
.boton-submit:hover{
    background-color: #3659E3 !important;
}

.descripcion-boton {
    display: block;
    font-size: 12px; 
    color: #555; 
    margin-top: 2px;
}

.info-input-img{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}
.ul-info-img{
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    text-wrap: break-word;
}

.contenedor-error{
    margin-top: 10px;
    padding: 5px 15px 5px 8px;
    border: 1px solid red;
    background-color: #FBEFEB;
    border-radius: 4px;
}

.input-select-evento{
    border: 1px solid #CDCDCD;
    border-radius: 4px;
    margin-top: 5px;
    padding: 8px;
}

.input-select-evento:hover{
    border: 1px solid black;
}

.input-select-evento:focus{
    border: 2px solid #1976D2;
}

